import Constants from '../../../../constants';
import OAuthButton from '../Shared/oauth-button';
import { OAuthProviderConfig } from '../Shared/oauth-callback';
import useOAuth from '../Shared/use-oauth';

const FacebookLoginButton = () => {
    const facebookOAuthConstants: OAuthProviderConfig = Constants.OAUTH_SOCIAL_LOGIN.FACEBOOK;
    const { openPopup } = useOAuth({
        subdomain: facebookOAuthConstants.SUBDOMAIN,
        scope: facebookOAuthConstants.SCOPE,
        identityProvider: facebookOAuthConstants.IDENTITY_PROVIDER,
    });

    return <OAuthButton iconUrl="/assets/auth-icons/fb-logo.png" text="Sign in with Facebook" onClick={openPopup} />;
};

export default FacebookLoginButton;
