import React, { useState } from 'react';
import { Table } from 'reactstrap';
import styled from 'styled-components';

import { ProjectResponse } from '../../../api/api-draw';

import DrawProjectsItem from './draw-projects-item';

import UriHelper from '../../../lib/uri-helper';

import DeleteModal from '../../Shared/Modal/delete';

import { actionDeleteDrawProject, actionResetDrawProject } from '../../../store/Map/DrawProject/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsDeletingDrawProject } from '../../../store/Map/DrawProject/selectors';

import Analytics from '../../../lib/user-analytics';
import ProjectPermissionsModal from '../../MapView/Annotations/Project/Permissions/project-permissions-modal';
import DrawProjectShareDialog from './draw-project-share-dialog';

interface DrawProjectsListProps {
    drawProjects: ProjectResponse[];
    onEditProject: (projectId: number) => void;
    onInvalidateProjects: () => void;
    isShared?: boolean;
    dataTestId?: string;
}

const DrawProjectsList = (props: DrawProjectsListProps) => {
    const { drawProjects: projects } = props;
    const [forDeleteProject, setForDeleteProject] = useState<ProjectResponse | undefined>();
    const [forShareProject, setForShareProject] = useState<ProjectResponse | undefined>();
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
    const [showProjectPermissionsModal, setShowProjectPermissionsModal] = useState<ProjectResponse | undefined>(
        undefined
    );
    const isDeletingProject = useSelector(selectIsDeletingDrawProject);

    const dispatch = useDispatch();

    const onShareProject = (selectedProject: ProjectResponse) => {
        Analytics.Event('Draw Tools - Dashboard', 'Clicked to share', selectedProject.id);
    };

    const viewProject = (selectedProject: ProjectResponse) => {
        Analytics.Event('Draw Tools - Dashboard', 'Clicked to view', selectedProject.id);
        dispatch(actionResetDrawProject());
        UriHelper.navigateToDrawProject(`${selectedProject.id}`);
    };

    const editProject = (selectedProject: ProjectResponse) => {
        Analytics.Event('Draw Tools - Dashboard', 'Clicked to edit project', selectedProject.id);
        UriHelper.navigateToPath('/draw/edit/' + selectedProject.id);
    };

    const editProjectDetails = (selectedProject: ProjectResponse) => {
        Analytics.Event('Draw Tools - Dashboard', 'Clicked to edit details', selectedProject.id);
        props.onEditProject(selectedProject.id);
    };

    const deleteProject = async () => {
        if (forDeleteProject) {
            Analytics.Event('Draw Tools - Dashboard', 'Clicked to delete', forDeleteProject.id);
            dispatch(actionDeleteDrawProject(forDeleteProject.id));
            setShowConfirmDelete(false);
        }
    };

    return (
        <React.Fragment>
            <ProjectsTable>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Last Modified</th>
                        <th>Date Created</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {projects.map((project) => {
                        return (
                            <DrawProjectsItem
                                dataTestId={props.dataTestId && `${props.dataTestId}-item`}
                                key={project.id}
                                drawProject={project}
                                onShare={() => {
                                    setForShareProject(project);
                                    setShowShareModal(true);
                                }}
                                onViewProject={() => {
                                    viewProject(project);
                                }}
                                onEdit={() => {
                                    editProject(project);
                                }}
                                onEditDetails={() => {
                                    editProjectDetails(project);
                                }}
                                onDelete={() => {
                                    setForDeleteProject(project);
                                    setShowConfirmDelete(true);
                                }}
                                onOpenPermissions={() => {
                                    setShowProjectPermissionsModal(project);
                                }}
                            />
                        );
                    })}
                </tbody>
            </ProjectsTable>
            <DeleteModal
                isOpen={showConfirmDelete}
                title="Delete Stoary"
                message="Are you sure you want to delete this Stoary?"
                onToggle={() => setShowConfirmDelete(false)}
                onDelete={() => deleteProject()}
                isDeleting={isDeletingProject}
            />
            {showShareModal && forShareProject ? (
                <DrawProjectShareDialog
                    project={forShareProject}
                    isOpen={true}
                    toggle={() => setShowShareModal(false)}
                    onCopy={() => onShareProject(forShareProject)}
                />
            ) : null}

            {showProjectPermissionsModal ? (
                <ProjectPermissionsModal
                    isOpen={true}
                    toggle={() => {
                        setShowProjectPermissionsModal(undefined);
                    }}
                    onInvalidateProjects={props.onInvalidateProjects}
                    drawProject={showProjectPermissionsModal}
                    invitedUserEmails={
                        showProjectPermissionsModal.sharePermissions
                            ? showProjectPermissionsModal.sharePermissions.map((p) => p.email)
                            : []
                    }
                />
            ) : null}
        </React.Fragment>
    );
};

export default DrawProjectsList;

const ProjectsTable = styled(Table)`
    background-color: transparent;
    color: white;
    margin-bottom: 80px;

    th {
        color: white !important;
        border-top: none;
        border-bottom: none !important;
        min-width: 120px;
    }

    @media only screen and (max-width: 1600px) {
        th {
            font-size: 12px;
            min-width: 110px;
        }
        tbody {
            font-size: 12px;
        }
    }
`;
