import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ProjectResponse } from '../../../api/api-draw';
import SoarModal, { SoarModalProps, StyledModalBody, StyledModalFooter } from '../../Shared/soar-modal';
import { StyledButton } from '../../Shared/styled-button';
import CopyText from '../../Shared/copy-text';
import { getShareProjectToClipboard } from '../../MapView/Annotations/Share/share-util';

interface DrawProjectShareDialogProps extends SoarModalProps {
    project: ProjectResponse | undefined;
    onCopy?: () => void;
}

const DrawProjectShareDialog = (props: DrawProjectShareDialogProps) => {
    const [projectLink, setProjectLink] = useState<string | undefined>();
    const [copyNotice, setCopyNotice] = useState<string | undefined>();

    useEffect(() => {
        if (props.project) {
            setProjectLink(getShareProjectToClipboard(props.project.id));
        }
    }, [props.project]);

    const onCopyLink = () => {
        setCopyNotice('Link copied to clipboard');
        setTimeout(() => setCopyNotice(undefined), 10000);

        if (props.onCopy) {
            props.onCopy();
        }
    };

    return (
        <DrawProjectShareModal title="Share Stoary" isOpen={props.isOpen} toggle={props.toggle}>
            <DrawProjectShareModalBody>
                <CopyText text={projectLink ?? ''} onCopy={() => onCopyLink()} />
                {copyNotice && <CopyTextNotice>{copyNotice}</CopyTextNotice>}
            </DrawProjectShareModalBody>
            <DrawProjectShareModalFooter>
                <ShareCloseButton onClick={props.toggle}>Close</ShareCloseButton>
            </DrawProjectShareModalFooter>
        </DrawProjectShareModal>
    );
};

export default DrawProjectShareDialog;

const DrawProjectShareModal = styled(SoarModal)``;

const DrawProjectShareModalBody = styled(StyledModalBody)``;

const DrawProjectShareModalFooter = styled(StyledModalFooter)`
    justify-content: center;
`;

const ShareCloseButton = styled(StyledButton)``;

const CopyTextNotice = styled.p`
    color: ${(props) => props.theme.color.yellow} !important;
`;
