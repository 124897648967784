import { MutableRefObject, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { isMobileVersion } from '../../../lib/soar-helper';

/**
 * useMapInteractionControl
 *  Disables double click, scroll zoom and dragging on the map when the mouse is over the specified element
 * @param ref
 */
const useMapInteractionControl = (ref: MutableRefObject<HTMLElement | null>) => {
    const map = useMap();

    useEffect(() => {
        if (isMobileVersion) return;

        const disableInteractions = () => {
            map.doubleClickZoom.disable();
            map.scrollWheelZoom.disable();
            map.dragging.disable();
        };

        const enableInteractions = () => {
            map.doubleClickZoom.enable();
            map.scrollWheelZoom.enable();
            map.dragging.enable();
        };

        const element = ref.current;
        if (element) {
            element.addEventListener('mouseenter', disableInteractions);
            element.addEventListener('mouseleave', enableInteractions);
        }

        return () => {
            if (element) {
                element.removeEventListener('mouseenter', disableInteractions);
                element.removeEventListener('mouseleave', enableInteractions);
            }
        };
    }, [map, ref]);
};

export default useMapInteractionControl;
