export const actionTypes = {
    UPDATE_CONFIG: 'CONFIG/UPDATE_CONFIG',
    UPDATE_CONFIG_SUCCESS: 'CONFIG/UPDATE_CONFIG_SUCCESS',
    UPDATE_CONFIG_FAILURE: 'CONFIG/UPDATE_CONFIG_FAILURE',

    FLY_TO: 'MAP/FLY_TO',
    FLY_TO_IMMEDIATELY: 'MAP/FLY_TO_IMMEDIATELY',
    FLY_TO_POSITION: 'MAP/FLY_TO_POSITION',
    FLY_TO_POSITION_INVALIDATE: 'MAP/FLY_TO_INVALIDATE',
    FLY_TO_ZOOM: 'MAP/FLY_TO_ZOOM',
    FLY_TO_FIX_ANTI_MERIDIAN: 'MAP/FLY_TO_FIX_ANTIMERIDIAN',

    SHOW_LOGIN_DIALOG: 'LOGIN/SHOW_DIALOG',
    SHOW_NAVBAR: 'NAVBAR/SHOW',
    SET_LOGIN_DIALOG_CLOSE_REDIRECT: 'LOGIN/SET_ON_CLOSE_REDIRECT',
    RESET_LOGIN_DIALOG_CLOSE_REDIRECT: 'LOGIN/RESET_ON_CLOSE_REDIRECT',

    SET_MAP_BOUNDS: 'SET/MAP_BOUNDS',
    SET_MAP_ZOOM: 'SET/MAP_ZOOM',

    SHOW_UPLOAD_DIALOG: 'UPLOAD/SHOW_DIALOG',
    MAP_IS_MOVING: 'MAP/IS_MOVING',
};
