import styled from 'styled-components';

interface OAuthButtonProps {
    iconUrl: string;
    text: string;
    onClick: () => void;
}

const OAuthButton = ({ iconUrl, text, onClick }: OAuthButtonProps) => {
    return (
        <OAuthStyledButton onClick={onClick}>
            <OAuthStyledButtonContent>
                <OAuthStyledButtonIcon src={iconUrl} />
                <OAuthStyledButtonText>{text}</OAuthStyledButtonText>
            </OAuthStyledButtonContent>
        </OAuthStyledButton>
    );
};

export default OAuthButton;

// Styles applied reflective of the google oauth login button
const OAuthStyledButton = styled.button`
    background-color: rgb(19, 19, 20);
    border: 1px solid rgb(142, 145, 143);
    border-radius: 4px;
    height: 40px;
    letter-spacing: 0.25px;
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
    width: 100%;
    margin: 5px auto;
    outline: none !important;

    :hover {
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15);
        border-color: #747775;
        background-color: #1a1a1b;
    }
`;

const OAuthStyledButtonContent = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    margin-left: 75px;
`;

const OAuthStyledButtonIcon = styled.img`
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
`;

const OAuthStyledButtonText = styled.span`
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
`;
