import React, { useEffect } from 'react';
import styled from 'styled-components';
import { TruncatedText } from '../../Shared/truncated-text';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectResponse } from '../../../api/api-draw';

import { deleteAllAnnotationsAction } from '../../../store/Annotations/actions';
import { actionResetDrawProject } from '../../../store/Map/DrawProject/actions';
import {
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
} from '../../../store/Map/Sentinel/actions';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';

import DrawProjectShare from '../../Dashboard/DrawProjects/draw-project-share';
import { StyledButton } from '../../Shared/styled-button';
import OpacitySlider from '../Maps/active-map-opacity-slider';
import ProfileName from '../Profile/profile-name';

import SoarHelper from '../../../lib/soar-helper';
import UriHelper from '../../../lib/uri-helper';
import Analytics from '../../../lib/user-analytics';

interface ActiveProjectDrawerProps {
    drawProject: ProjectResponse;
    handleBack: () => void;
}

const MAX_DESCRIPTION_LENGTH = 400;

const ActiveProjectDrawer = ({ drawProject, handleBack }: ActiveProjectDrawerProps) => {
    const isSoar = SoarHelper.isSoar();

    const activeMap = useSelector(selectActiveAllMap);

    const handleShowProfile = () => {
        Analytics.Event('Draw Tools - Project', 'Clicked view profile from project', {
            ownerId: drawProject.ownerId,
            projectId: drawProject.id,
        });
        UriHelper.navigateToPath(`/profile/${drawProject.ownerId}`);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            const resetDrawProject = () => {
                dispatch(actionResetDrawProject());
                dispatch(deleteAllAnnotationsAction());
                dispatch(actionSentinelResetSelectedFeature());
                dispatch(actionSentinelResetSelectedAOI());
                dispatch(actionActiveMapCleared());
            };
            // Draw is no longer mounted so we do a full reset of the project
            const drawPath = '/draw/';
            const isDrawPath = UriHelper.isPathMatchingPattern(drawPath);
            if (!isDrawPath) {
                resetDrawProject();
            }
        };
    }, [dispatch]);

    if (!drawProject) {
        return <React.Fragment />;
    }

    return (
        <DrawProjectContainer>
            <StyledDrawerHeader>
                <Title title={drawProject.title || 'Untitled Stoary'}>{drawProject.title || 'Untitled Stoary'}</Title>
            </StyledDrawerHeader>
            <StyledAuthorContainer>
                A Stoary by
                {isSoar ? (
                    <UserProfileLink
                        userId={drawProject.ownerId}
                        className="user-profile-link"
                        onClick={handleShowProfile}
                        isCommunityModerator={false}
                        isReputable={drawProject.ownerReputable}
                    >
                        {drawProject.ownerName || 'Soar User'}
                    </UserProfileLink>
                ) : (
                    <SubdomainProfileLink> {drawProject.ownerName || 'Soar User'}</SubdomainProfileLink>
                )}
            </StyledAuthorContainer>
            <Content>
                {drawProject.description ? (
                    <TruncatedText text={drawProject.description} length={MAX_DESCRIPTION_LENGTH} />
                ) : null}
            </Content>
            <DrawerFooter>
                {activeMap && (
                    <DrawerLayerOpacity>
                        <OpacitySlider tileLayer={activeMap} />
                    </DrawerLayerOpacity>
                )}
                <DrawerShareContainer>
                    <DrawProjectShareIcons>
                        <DrawProjectShare />
                    </DrawProjectShareIcons>
                    <CloseProjectButton
                        onClick={() => {
                            Analytics.Event('Draw Tools - Project', 'Closed viewing project', {
                                ownerId: drawProject.ownerId,
                                projectId: drawProject.id,
                            });
                            handleBack();
                        }}
                    >
                        Close
                    </CloseProjectButton>
                </DrawerShareContainer>
            </DrawerFooter>
        </DrawProjectContainer>
    );
};

export default ActiveProjectDrawer;

const DrawProjectContainer = styled.div`
    min-height: 0px;
`;

const UserProfileLink = styled(ProfileName)`
    display: inline-flex;
    width: auto;
    max-width: 90%;
    font-size: 16px !important;
    margin: 0 !important;

    a {
        min-width: 0;
        color: #eed926 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
    }
`;

const SubdomainProfileLink = styled.span`
    font-size: 16px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: normal;
    padding: 0px 3px;
`;

const StyledDrawerHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    padding: 0;
`;

const StyledAuthorContainer = styled.span`
    display: flex;
    flex-direction: row;
    margin: 0px 0px 0px 12px;
    padding-right: 6px;
    color: rgba(255, 255, 255, 0.67);
    font-size: 16px;
    white-space: nowrap;

    ${UserProfileLink} {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        padding-left: 3px;
        cursor: help;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

const Title = styled.h1`
    flex: 0 1 auto;
    margin: 0 12px;
    color: white;
    text-align: left;
    font-weight: 700;
    font-size: 30px;
    max-width: 440px;
    -webkit-line-clamp: 5;
    display: box;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    line-height: 3rem;
    word-break: break-word;
    hyphens: auto;
`;

const Content = styled.div`
    flex: 1 1 auto;
    margin: 12px;
    text-align: left;
    font-weight: 400;
    line-height: 20.8px;
    color: white;
    word-break: break-word;
    min-height: 30px;
`;

const DrawerFooter = styled.div`
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 10px 10px;
    background: rgb(255, 255, 255, 5%);
    width: 100%;
`;

const DrawerLayerOpacity = styled.div`
    flex: 1;
`;

const DrawerShareContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    align-items: center;

    i {
        margin: 0;
    }
`;

const CloseProjectButton = styled(StyledButton)`
    padding: 4px 8px;
`;

const DrawProjectShareIcons = styled.div`
    width: 110px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
