import Constants from '../../../../constants';
import OAuthButton from '../Shared/oauth-button';
import { OAuthProviderConfig } from '../Shared/oauth-callback';
import useOAuth from '../Shared/use-oauth';

const GoogleLoginButton = () => {
    const googleOAuthConstants: OAuthProviderConfig = Constants.OAUTH_SOCIAL_LOGIN.GOOGLE;
    const { openPopup } = useOAuth({
        subdomain: googleOAuthConstants.SUBDOMAIN,
        scope: googleOAuthConstants.SCOPE,
        identityProvider: googleOAuthConstants.IDENTITY_PROVIDER,
    });

    return <OAuthButton iconUrl="/assets/auth-icons/google-logo.png" text="Sign in with Google" onClick={openPopup} />;
};

export default GoogleLoginButton;
