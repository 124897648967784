import SoarHelper from '../../../lib/soar-helper';
import FacebookLoginButton from './Facebook/facebook-login-button';
import GoogleLoginButton from './Google/google-login-button';
import styled from 'styled-components';

const OAuthSocialLogins = () => {
    // If the user is on the Soar app or a subdomain, return null
    if (SoarHelper.isSoarIOS() || !SoarHelper.isSoar()) return null;

    return (
        <OAuthSocialLoginContainer>
            <GoogleLoginButton />
            <FacebookLoginButton />
            <OAuthSocialLoginOrText>or</OAuthSocialLoginOrText>
        </OAuthSocialLoginContainer>
    );
};

export default OAuthSocialLogins;

const OAuthSocialLoginContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 5px;
`;

const OAuthSocialLoginOrText = styled.div`
    font-size: 14px;
    margin: 10px 0;
    align-self: center;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 150px;

    &::before,
    &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        margin: 0 10px;
    }
`;
