import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MapContainer as LeafletMapContainer, useMap } from 'react-leaflet';
import styled from 'styled-components';
import ApiDraw from '../../../../../api/api-draw';
import CoordinatesControl from '../../../Coordinates/coordinates-control';
import GlobeMiniMapControl from '../../../MiniMap/globe-minimap-control';
import TileLayers from '../../../TileLayers/tilelayers';
import AnnotationPatterns from '../../../annotation-patterns';
import Basemaps from '../../../basemaps';
import MapScaleControl from '../../../map-scale-control';
import MapViewDispatcher from '../../../map-view-dispatcher';
import ArrowControl from '../../Arrow/arrow-control';
import CircleControl from '../../Circle/circle-control';
import CoordinateControl from '../../Coordinate/coordinate-control';
import FreehandPolygonControl from '../../FreehandPolygon/freehand-polygon-control';
import FreehandPolylineControl from '../../FreehandPolyline/freehand-polyline-control';
import ImageControl from '../../ImageTool/image-control';
import MarkerControl from '../../Marker/marker-control';
import MilitaryMarkerControl from '../../MilitaryMarker/military-marker-control';
import PolygonControl from '../../Polygon/polygon-control';
import PolylineControl from '../../Polyline/polyline-control';
import GeoJSONProject from '../../Project/geojson-project';
import ProjectAccess from '../../Project/project-access';
import { useProjectAnnotationHandler } from '../../Project/use-project-annotation-handler';
import RectangleControl from '../../Rectangle/rectangle-control';
import TextControl from '../../Text/text-control';
import ZoomControl from '../../Zoom/zoom-control';
import { DrawPage } from '../draw-story';
import StoryMapSidedrawer from './story-map-sidedrawer';
import StoryMapsControl from './story-maps-control';
import { PulseLoader } from '../../../../Shared/pulse-loader';
import ThinDrawer from '../../../../Drawer/Shared/Drawer/thin-drawer';
import DrawerHeader from '../../../../Drawer/Shared/Drawer/drawer-header';
import { useBasemap } from '../../../../../store/Map/Basemap/use-basemap';
import { actionSetActiveMapById, actionActiveMapCleared } from '../../../../../store/Map/SuperMap/actions';
import { actionSetTileLayerOpacity } from '../../../../../store/Map/TileLayer/actions';

import { actionSetSideDrawerModeAction } from '../../../../../store/SideDrawer/actions';
import { SideDrawerMode } from '../../../../../store/SideDrawer/model';

// Values that give the map an aesthetically pleasing start position
const WORLD_ZOOM = 2.5;
const WORLD_CENTER: [number, number] = [34.5, 0];
export const MAX_ZOOM_LEVEL = 28;

interface StoryProps {
    selectedIndex: number;
    selectedPage: DrawPage;
    numberOfPages: number;
    currentPageIndex: number;
    updatedDatestamp?: string;
    onNextPage: () => void;
    onPreviousPage: () => void;
    onMapAutoZoomFinished: () => void;
}

const Story = (props: StoryProps) => {
    const { handleSetProjectAnnotations } = useProjectAnnotationHandler();
    const { setBasemap } = useBasemap();
    const map = useMap();
    const dispatch = useDispatch();

    const [hasMapAutoZoomFinished, setHasMapAutoZoomFinished] = useState(false);

    useEffect(() => {
        if (props.selectedPage && props.selectedPage.project) {
            handleSetProjectAnnotations(
                props.selectedPage.project,
                props.selectedPage?.viewportLocked,
                props.selectedPage?.allViewportsLocked
            );

            if (props.selectedPage?.activeBaseMap) {
                setBasemap(props.selectedPage.activeBaseMap);
            }

            if (props.selectedPage?.map) {
                dispatch(actionSetActiveMapById(props.selectedPage.map.id));
                if (props.selectedPage.map.opacity) {
                    dispatch(actionSetTileLayerOpacity(props.selectedPage.map.id, props.selectedPage.map.opacity));
                }
            } else {
                dispatch(actionActiveMapCleared());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedPage]);

    useEffect(() => {
        const onZoomEnd = () => {
            setHasMapAutoZoomFinished(true);
            map.off('zoomend', onZoomEnd);
        };

        const timer = setTimeout(() => {
            setHasMapAutoZoomFinished(true);
        }, 4000);

        map.on('zoomend', onZoomEnd);

        return () => {
            clearTimeout(timer);
            map.off('zoomend', onZoomEnd);
        };
    }, [map]);

    useEffect(() => {
        if (hasMapAutoZoomFinished) {
            props.onMapAutoZoomFinished();
        }
    }, [hasMapAutoZoomFinished, props]);

    return (
        <React.Fragment>
            {props.numberOfPages > 1 ? (
                <StoryMapsControl
                    currentPageIndex={props.currentPageIndex}
                    totalPages={props.numberOfPages}
                    onNextPage={() => {
                        if (props.currentPageIndex < props.numberOfPages) {
                            props.onNextPage();
                        }
                    }}
                    onPreviousPage={() => {
                        if (props.currentPageIndex >= 0) {
                            props.onPreviousPage();
                        }
                    }}
                />
            ) : null}

            <StoryMapSidedrawer
                numberOfPages={props.numberOfPages}
                projectTitle={(props.selectedPage && props.selectedPage.projectTitle) || ''}
                pageTitle={(props.selectedPage && props.selectedPage.pageTitle) || ''}
                description={(props.selectedPage && props.selectedPage.description) || ''}
                userName={props.selectedPage?.userName || ''}
                userId={props.selectedPage?.userId || ''}
                reputable={props.selectedPage?.ownerReputable || false}
                updatedDatestamp={props.updatedDatestamp}
            />
        </React.Fragment>
    );
};

interface ViewStoryMapProps {
    drawProjectId: number;
}

const ViewStoryMap = (props: ViewStoryMapProps) => {
    const [hideAnnotationsDueToAutoZoom, setHideAnnotationsDueToAutoZoom] = useState(true);
    const [drawPages, setDrawPages] = useState<DrawPage[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [error, setError] = useState<string | undefined>(undefined);

    const dispatch = useDispatch();

    useEffect(() => {
        ApiDraw.getDrawProject(props.drawProjectId)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((res: any) => {
                if (res.data && res.data.pages) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const pagesRaw: any[] = res.data.pages;
                    const pages: DrawPage[] = pagesRaw.map((page, index) => {
                        const project = GeoJSONProject.import(JSON.stringify(page));

                        const map = page.mapLayers
                            ? {
                                  id: page.mapLayers.id,
                                  title: page.mapLayers.title,
                                  author: page.mapLayers.author ?? 'Soar User',
                                  opacity: page.mapLayers.opacity,
                              }
                            : page.map;

                        const drawPage: DrawPage = {
                            projectTitle: res.title,
                            pageTitle: page.pageTitle || page.title,
                            index: index,
                            id: page.project.id,
                            description: page.description || res.description,
                            project: project,
                            map: map,
                            activeMap: map?.id,
                            userName: res.ownerName,
                            userId: res.ownerId,
                            ownerReputable: res.ownerReputable,
                            updatedAt: res.updatedAt,
                            createdAt: res.createdAt,
                            viewportLocked: page.viewportLocked,
                            allViewportsLocked: page.allViewportsLocked,
                            activeBaseMap: page.activeBaseMap,
                        };
                        return drawPage;
                    });
                    setDrawPages(pages);
                } else if (res.data) {
                    const drawPage: DrawPage = {
                        projectTitle: res.title,
                        index: 0,
                        id: res.id,
                        description: res.description,
                        project: GeoJSONProject.import(JSON.stringify(res.data)),
                        map: res.mapIds ? { id: res.mapIds[0], title: res.title, author: res.ownerName } : undefined,
                        userName: res.ownerName,
                        userId: res.ownerId,
                        ownerReputable: res.ownerReputable,
                        updatedAt: res.updatedAt,
                        createdAt: res.createdAt,
                        viewportLocked: res.viewportLocked,
                        allViewportsLocked: res.allViewportsLocked,
                        activeBaseMap: res.activeBaseMap,
                    };
                    setDrawPages([drawPage]);
                }

                dispatch(actionSetSideDrawerModeAction(SideDrawerMode.SOAR_DRAW_VIEW));
            })
            .catch((error) => {
                setError(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [props.drawProjectId, error, dispatch]);

    return (
        <React.Fragment>
            <Container>
                <LeafletMapContainer
                    zoomSnap={0.01}
                    maxBoundsViscosity={1.0}
                    maxZoom={MAX_ZOOM_LEVEL}
                    zoom={WORLD_ZOOM}
                    center={WORLD_CENTER}
                    minZoom={WORLD_ZOOM}
                    className="leaflet-map"
                    id="leaflet-map"
                    zoomControl={false}
                >
                    <React.Fragment>
                        {error ? (
                            <ProjectAccess error={error} resetError={() => setError(undefined)} />
                        ) : (
                            <React.Fragment>
                                {loading ? (
                                    <ThinDrawer style={{ minWidth: '286px' }}>
                                        <DrawerHeader />
                                        <PulseLoaderContainer>
                                            <PulseLoader />
                                        </PulseLoaderContainer>
                                    </ThinDrawer>
                                ) : (
                                    <Story
                                        selectedIndex={currentPageIndex}
                                        selectedPage={drawPages[currentPageIndex]}
                                        numberOfPages={drawPages.length}
                                        currentPageIndex={currentPageIndex}
                                        onNextPage={() => setCurrentPageIndex(currentPageIndex + 1)}
                                        onPreviousPage={() => setCurrentPageIndex(currentPageIndex - 1)}
                                        onMapAutoZoomFinished={() => setHideAnnotationsDueToAutoZoom(false)}
                                        updatedDatestamp={drawPages[currentPageIndex]?.updatedAt}
                                    />
                                )}
                            </React.Fragment>
                        )}
                        <AnnotationPatterns />
                        <MapViewDispatcher />
                        <CoordinatesControl />
                        <GlobeMiniMapControl />
                        <ZoomControl />
                        <MapScaleControl />
                        <Basemaps />
                        <TileLayers editMode={true} />

                        {hideAnnotationsDueToAutoZoom ? null : (
                            <React.Fragment>
                                <FreehandPolylineControl visible={false} locked={true} disabled={true} />
                                <FreehandPolygonControl visible={false} locked={true} disabled={true} />
                                <PolygonControl visible={false} locked={true} disabled={true} />
                                <PolylineControl visible={false} locked={true} disabled={true} />
                                <ArrowControl visible={false} locked={true} disabled={true} />
                                <CircleControl visible={false} locked={true} disabled={true} />
                                <RectangleControl visible={false} locked={true} disabled={true} />
                                <TextControl visible={false} locked={true} disabled={true} />
                                <MarkerControl visible={false} locked={true} disabled={true} />
                                <CoordinateControl visible={false} locked={true} disabled={true} />
                                <ImageControl visible={false} locked={true} disabled={true} />
                                <MilitaryMarkerControl visible={false} locked={true} disabled={true} />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </LeafletMapContainer>
            </Container>
        </React.Fragment>
    );
};

export default ViewStoryMap;

const Container = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    padding: 0;
    margin: 0;

    @media (max-width: 500px) {
        position: absolute;
        top: 80px;
        bottom: 0px;
        width: 100%;
    }

    .leaflet-control-layers.leaflet-control a.leaflet-control-layers-toggle {
        vertical-align: bottom;
        display: table-cell;
    }

    .leaflet-right .leaflet-control {
        margin: 0;
        padding: 0;
    }

    .leaflet-control-better-scale.leaflet-control {
        right: 15px !important;
    }

    .leaflet-bottom.leaflet-right .leaflet-control-layers.leaflet-control a.leaflet-control-layers-toggle {
        display: none;
    }
`;

const PulseLoaderContainer = styled.div`
    padding: 30px 0;
`;
