import styled from 'styled-components';
import { Input } from 'reactstrap';

export interface PreviewTileProps {
    onClick?: () => void;
    previewUrl?: string;
    title?: string;
    subText?: string;
    opacity?: number;
    onUpdateOpacity?: (newOpacity: number) => void;
}

const PreviewTile = (props: PreviewTileProps) => {
    const handleImgError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        event.currentTarget.src = '/assets/image-unavailable/preview_unavailable.png';
        event.currentTarget.style.width = '50px';
        event.currentTarget.style.marginLeft = '6px';
    };
    return (
        <Container>
            <PreviewImageContainer>
                <PreviewImage
                    src={props.previewUrl || '/assets/image-unavailable/preview_unavailable.png'}
                    onError={handleImgError}
                    onClick={props.onClick}
                />
            </PreviewImageContainer>
            <ContentContainer>
                <Title onClick={props.onClick}>{props.title}</Title>
                <SubText>{props.subText}</SubText>
                {props.onUpdateOpacity ? (
                    <SliderContainer className="opacity-slider">
                        <SliderText>Transparency:</SliderText>
                        <SliderInputContainer>
                            <Slider
                                type="range"
                                value={props.opacity && props.opacity * 100}
                                step={0.01}
                                onChange={(e: { target: { value: string } }) => {
                                    const newOpacity = parseInt(e.target.value) / 100;
                                    props.onUpdateOpacity &&
                                        props.onUpdateOpacity(newOpacity === 0 ? 0.001 : newOpacity);
                                }}
                            />
                        </SliderInputContainer>
                    </SliderContainer>
                ) : null}
            </ContentContainer>
        </Container>
    );
};

export default PreviewTile;

const Container = styled.div`
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: horizontal;
    box-shadow: none;
`;

const PreviewImageContainer = styled.div`
    height: 100%;
    width: 25%;
    max-width: 64px;
`;

const PreviewImage = styled.img`
    height: 100%;
    width: 100%;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    margin: 0;
    padding: 0;
    object-fit: cover;
    cursor: pointer;
`;

const ContentContainer = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin: 2px 0 0 4px;
    width: 75%;
`;

const Title = styled.p`
    color: #eed926 !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    flex: 1 0 auto;
    margin: 0;
    padding: 0;
`;

const SubText = styled.p`
    color: rgba(255, 255, 255, 0.5);
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    margin: 0;
`;

const SliderContainer = styled.div`
    width: 100%;
    display: flex;
`;

const SliderText = styled.p`
    vertical-align: middle;
    color: white;
    font-size: ${(props) => props.theme.fontSize.normal};
    margin: 0;
    padding: 0;
`;

const SliderInputContainer = styled.div`
    width: 100%;
    margin-left: 4px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Slider = styled<any>(Input)`
    width: 100%;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
        #eed926 ${(props) => 100 - props.value}%
    );
    height: 2px;
    padding: 0;
    box-shadow: none !important;
    border: none !important;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border-radius: 5px;
    margin-top: 10px;

    &::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        cursor: pointer;
    }

    &:hover::-moz-range-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 50%;
        cursor: pointer;
    }

    &:hover::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 50%;
        cursor: pointer;
    }

    &:focus {
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.3) ${(props) => 100 - props.value}%,
            #eed926 ${(props) => 100 - props.value}%
        );
    }
`;
