import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LinkButton } from '../../../../UI/Button/button';
import DeleteConfirmModal from '../../DeleteAll/delete-confirm-modal';
import { DrawPage } from '../draw-story';
import StoryBuilderPageDetailsModal from './story-builder-page-details-modal';
import StoryBuilderPageItemTitle from './story-builder-page-item-title';
import { useDispatch } from 'react-redux';
import { setSelectedAnnotationAction } from '../../../../../store/Annotations/actions';
import { Annotation } from '../../../../../store/Annotations/reducer';
import Analytics from '../../../../../lib/user-analytics';

interface StoryBuilderPageItemProps {
    page: DrawPage;
    pageNumber: number;
    isSelected: boolean;
    onSelect: (page: DrawPage) => void;
    onDelete: (page: DrawPage) => void;
    onSetPageViewport: (locked: boolean) => void;
    onSetAllPagesViewport: (allLocked: boolean) => void;

    onUpdateMapFromPage: (page: DrawPage) => void;
    onDeleteMapFromPage: (page: DrawPage) => void;
    onUpdatePageTitle: (title: string) => void;
    onUpdatePageDescription: (description: string) => void;
    onSelectListing: (listingId: number) => void;
    onMovePageUp: (page: DrawPage) => void;
    onMovePageDown: (page: DrawPage) => void;
}

const StoryBuilderPageItem = (props: StoryBuilderPageItemProps) => {
    const [showDetails, setShowDetails] = useState(false);
    const [doubleClicked, setDoubleClicked] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const dispatch = useDispatch();
    const setSelectedAnnotation = (annotation: Annotation | undefined) =>
        dispatch(setSelectedAnnotationAction(annotation));

    const handleDoubleClick = () => {
        setDoubleClicked(true);
        setShowDetails(true);
    };

    const handleClick = () => {
        if (doubleClicked) {
            setDoubleClicked(false);
        } else {
            props.onSelect(props.page);
        }
    };

    useEffect(() => {
        if (!props.isSelected) {
            setShowDetails(false);
        }
    }, [props.isSelected]);

    return (
        <React.Fragment>
            <Row>
                <ItemContainer
                    isSelected={props.isSelected}
                    isDetailsOpen={showDetails}
                    onDoubleClick={handleDoubleClick}
                    onClick={handleClick}
                >
                    <React.Fragment>
                        <StoryBuilderPageItemTitle
                            page={props.page}
                            isSelected={props.isSelected}
                            handleShowDetails={() => {
                                Analytics.Event('Draw Tools - Project', `Clicked to edit page details`);
                                setSelectedAnnotation(undefined);
                                setShowDetails(true);
                            }}
                            handleHideDetails={() => setShowDetails(false)}
                        />

                        <StoryBuilderPageDetailsModal
                            isOpen={showDetails}
                            onToggle={() => {
                                setShowDetails(false);
                            }}
                            page={props.page}
                            onUpdateMapFromPage={props.onUpdateMapFromPage}
                            onDeleteMapFromPage={props.onDeleteMapFromPage}
                            onSelectListing={props.onSelectListing}
                            onUpdatePageTitle={props.onUpdatePageTitle}
                            onUpdatePageDescription={props.onUpdatePageDescription}
                            onMovePageDown={() => props.onMovePageDown(props.page)}
                            onMovePageUp={() => props.onMovePageUp(props.page)}
                            onSetAllPagesViewport={props.onSetAllPagesViewport}
                            onSetPageViewport={props.onSetPageViewport}
                        />
                    </React.Fragment>
                </ItemContainer>
                <DeleteButtonContainer>
                    {props.isSelected && props.pageNumber > 1 ? (
                        <DeleteButton title="Delete this page" onClick={() => setShowDeleteConfirm(true)}>
                            <DeleteButtonIcon src="/assets/annotations/icon-trash-red.svg" />
                        </DeleteButton>
                    ) : null}
                </DeleteButtonContainer>
            </Row>

            <DeleteConfirmModal
                title="Delete Page"
                message="Are you sure you want to delete this page? This will also remove all annotations on this page."
                isOpen={showDeleteConfirm}
                onToggle={() => setShowDeleteConfirm(false)}
                onConfirm={() => props.onDelete(props.page)}
                isExistingProject={false}
                cancelText="NO"
                confirmText="YES"
            />
        </React.Fragment>
    );
};

export default StoryBuilderPageItem;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const ItemContainer = styled.div<{ isSelected: boolean; isDetailsOpen: boolean }>`
    margin: 8px 0 0 8px;
    width: 100%;
    border: ${(props) =>
        props.isSelected ? `1px solid ${props.theme.color.yellow}` : `1px solid ${props.theme.color.lightGray}`};
    border-radius: ${(props) => props.theme.borderRadius};
    color: white;
    height: auto;
    overflow: hidden;
    background: ${(props) => props.theme.color.transparentDark};

    :hover {
        cursor: pointer;
        border: ${(props) =>
            props.isSelected ? `1px solid ${props.theme.color.yellow}` : `1px solid ${props.theme.color.lightGray}`};
    }
`;

const DeleteButtonContainer = styled.div`
    width: 28px;
    margin: 14px 0 0 0;
`;

const DeleteButton = styled(LinkButton)`
    display: flex;
    flex-direction: row;
    padding: 2px !important;
    box-shadow: none;
    color: white;
    cursor: pointer;
`;

const DeleteButtonIcon = styled.img`
    margin: 0;
    padding: 0;
    margin-right: 8px;
    display: block;
    height: 22px;
`;
